import React from "react";
import Header from "../components/header.js";
import Footer from "../components/footer.js";
import Certs from "../components/certs.js";

export default () => (
    <div>
        <Header/>
        <Certs/>
        <Footer />
    </div>
)