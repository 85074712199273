import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

export default (props) => (
    <div>
        <Card style={{ width: '12rem', border: 'none'}}>
            <Card.Img variant="top" src={props.image} alt="certificate" />
            <Card.Body className="text-center">
                <Button variant="secondary" href={props.link}>View</Button>
            </Card.Body>
        </Card>                   
    </div>    
)