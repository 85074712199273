import React from "react";
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import "./certs.css";

//aws badges
import aws_sp from "../images/aws-sp.png";
import aws_devops from "../images/aws-devops.png";
import aws_sec from "../images/aws-sec.png";
import aws_network from "../images/aws-network.png";
import aws_sa from "../images/aws-sa.png"
import aws_sysops from "../images/aws-sysops.png"
import aws_dev from "../images/aws-dev.png";
import aws_cp from "../images/aws-cp.png"

//azure badges
import az_admin from "../images/az-admin.png";
import az_dev from "../images/az-dev.png";
import az_devops from "../images/az-devops.png";
import az_fun from "../images/az-fun.png";
import az_sa from "../images/az-sa.png";

//google badges
import gcp_ass from "../images/gcp-ass.png";

//docker badges
import docker_cert from "../images/docker-associate.png";
import CertCards from "./cert-cards.js";
import SEO from "./seo.js";

export default () => (
    <div className="certs">

        <SEO
            title="Raywon's Certifications"
            description="Raywon's Certifications"
            pathname="/certs"
        />

        <p id="certs">Certifications</p>

        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
                <Col sm={3}>
                    <Nav variant="pills" className="flex-column tabs-column">
                        <Nav.Item>
                            <Nav.Link eventKey="first">AWS (8)</Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link eventKey="second">Azure (5)</Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link eventKey="third">Google (1)</Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link eventKey="fourth">Docker (1)</Nav.Link>
                        </Nav.Item>

                    </Nav>
                </Col>

                <Col sm={9}>
                    <Tab.Content className="tab-content">
                        <Tab.Pane eventKey="first">
                            <Row>
                                <CertCards image={aws_sp} link={"https://www.youracclaim.com/badges/0f496302-82e2-4c8c-bb6c-a6f8dd88980d/public_url"}/>
                                <CertCards image={aws_devops} link={"https://www.youracclaim.com/badges/84a679f8-3c2f-4af8-b93d-4a57a14e4b24/public_url"}/>
                                <CertCards image={aws_sec} link={"https://www.youracclaim.com/badges/bc1587cc-7aaa-49ad-bf0d-089228d08171/public_url"}/>
                                <CertCards image={aws_network} link={"https://www.youracclaim.com/badges/336612d3-4825-432d-a5f6-7e02e76b5559/public_url"}/>
                                <CertCards image={aws_sa} link={"https://www.youracclaim.com/badges/307453db-cb78-4e4c-972d-bc8293fb924f/public_url"}/>
                                <CertCards image={aws_sysops} link={"https://www.youracclaim.com/badges/139b9ae9-fab1-4d56-b992-dfab72bef4f3/public_url"}/>
                                <CertCards image={aws_dev} link={"https://www.youracclaim.com/badges/2c8eed08-dbf5-455b-8d90-7de0bf906798/public_url"}/>
                                <CertCards image={aws_cp} link={"https://www.youracclaim.com/badges/099c7fa8-de63-460e-88ca-23e61c14b04f/public_url"}/>
                            </Row>
                        </Tab.Pane>

                        <Tab.Pane eventKey="second">
                            <Row>
                                <CertCards image={az_sa} link={"https://www.youracclaim.com/badges/73ca3b60-4edf-439f-b60c-c0c16e9952eb/public_url"}/>
                                <CertCards image={az_devops} link={"https://www.youracclaim.com/badges/4f6d2eec-d60c-465b-abde-51ea26983bc3/public_url"}/>
                                <CertCards image={az_dev} link={"https://www.youracclaim.com/badges/e0ee079c-1782-4c48-9818-7d03ccc696b9/public_url"}/>
                                <CertCards image={az_admin} link={"https://www.youracclaim.com/badges/5a32db75-84cd-4980-a215-db113dc65864/public_url"}/>
                                <CertCards image={az_fun} link={"https://www.youracclaim.com/badges/1b0264b9-5db8-46c8-86a6-437d88fb3b12/public_url"}/>
                            </Row>
                        </Tab.Pane>

                        <Tab.Pane eventKey="third">
                            <Row>
                                <CertCards image={gcp_ass} link={"https://www.credential.net/74252020-a652-4bed-8b65-01371e44e29f"}/>
                            </Row>
                        </Tab.Pane>

                        <Tab.Pane eventKey="fourth">
                            <Row>
                                <CertCards image={docker_cert} link={"https://credentials.docker.com/84ea400c-3497-43ac-beb5-f1cf7e3504fe"}/>
                            </Row>
                        </Tab.Pane>                                                
                    </Tab.Content>
                </Col>

            </Row>
        </Tab.Container>

    </div>
)